import React, { useState } from 'react'

const HOMEPAGE = 'work'
type activePage = 'work' | 'licensing' | 'about' | '404'

export const myContext = React.createContext({
  activePage: HOMEPAGE,
  updateActivePage: (_page: activePage): void => undefined,
  windowWidth: 0,
  updateWindowWidth: (_width: number): void => undefined,
  userHasInteracted: false,
  updateUserHasInteracted: (_hasInteracted: boolean): void => undefined
})

export const Provider: React.FunctionComponent = props => {
  const [activePage, setActivePage] = useState(HOMEPAGE)
  const [windowWidth, setWindowWidth] = useState(0)
  const [userHasInteracted, setUserHasInteracted] = useState(false)

  const updateActivePage = (page: activePage) => setActivePage(page)
  const updateWindowWidth = (width: number) => setWindowWidth(width)
  const updateUserHasInteracted = (hasInteracted: boolean) => setUserHasInteracted(hasInteracted)

  return (
    <myContext.Provider
      value={{
        activePage,
        updateActivePage,
        windowWidth,
        updateWindowWidth,
        userHasInteracted,
        updateUserHasInteracted
      }}
    >
      {props.children}
    </myContext.Provider>
  )
}
